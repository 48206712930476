<template>
<div class="background">
  <div class="buffer"></div>
  <v-card max-width="580" class="mx-auto" color="secondary" >
      <v-toolbar color="accent" class="drumTitle" min-height="100px" >
        <div>
         <img width="90px" class="clefLogo" src="@/assets/Title.png">
         </div>
      </v-toolbar>
      <SoundControls 
      class="SoundControls"
        :tempo.sync="tempo"
        :counter="counter"
        :isPlaying="isPlaying"
        @play="play"
        @stop="stop"
      ></SoundControls>
      <!-- Add <SoundTracks></SoundTracks> component here -->
      <SoundTracks :tracks="tracks" @playsound="playSound" @mutesound="muteSound"></SoundTracks>
      <!-- Add <SoundControls></SoundControls> component here -->
      
      <!-- Add <SoundPresets></SoundPresets> component here -->
      <SoundPresets :currentPreset="currentPreset" @cleartracks="clearTracks" @loadpreset="loadPreset"></SoundPresets>
    </v-card>
</div>
</template>

<script>
import { Howl } from "howler";
import SoundTracks from '../components/SoundTracks.vue';
import SoundControls from '../components/SoundControls.vue';
import SoundPresets from '../components/SoundPresets.vue';
import StartAudioContext from 'startaudiocontext';

/* eslint-disable no-unused-vars */
const kick = new Howl({src: ["https://patmakes.github.io/webdrumsounds/1-kick-WebDrum2Sounds.mp3",],});
const snare = new Howl({src: ["https://patmakes.github.io/webdrumsounds/2-snare-WebDrum2Sounds.mp3",],});
const chh = new Howl({src: ["https://patmakes.github.io/webdrumsounds/3-chh-WebDrum2Sounds.mp3",],});
const ohh = new Howl({src: ["https://patmakes.github.io/webdrumsounds/4-ohh-WebDrum2Sounds.mp3",],});
const chord = new Howl({src: ["https://patmakes.github.io/webdrumsounds/5-chord-WebDrum2Sounds.mp3",],});
const clap = new Howl({src: ["https://patmakes.github.io/webdrumsounds/6-clap-WebDrum2Sounds.mp3",],});
/* eslint-enable no-unused-vars */

let audioContext = new AudioContext();

export default {
  name: 'App',
  components: { 
    SoundTracks,
    SoundControls,
    SoundPresets, },

data() {
  return {
    tempo: 120,
    tracks: {
      kick: [],
      snare: [],
      chh: [],
      ohh: [],
      chord: [],
      clap: [],
    },
    futureTickTime: audioContext.currentTime,
    counter: 0,
    timerID: null,
    isPlaying: false,
  };
},
computed: {
  secondsPerBeat() {
    return 60/ this.tempo;
  },
  counterTimeValue() {
    return this.secondsPerBeat / 4;
  },
  currentPreset() {
      return {
        tempo: this.tempo,
        tracks: this.tracks
      };
    }
},
methods: {
  scheduleSound(trackArray, sound, counter) {
    for (var i = 0; i < trackArray.length; i += 1) {
      if (counter === trackArray[i]) {
        sound.play();
      }
    }
  },
  playTick() {
    this.counter += 1;
    this.futureTickTime += this.counterTimeValue;
    if (this.counter > 15) {
      this.counter = 0;
    }
  },
  scheduler() {
    if (this.futureTickTime < audioContext.currentTime + 0.1) {
      this.scheduleSound(this.tracks.kick, kick, this.counter);
      this.scheduleSound(this.tracks.snare, snare, this.counter);
      this.scheduleSound(this.tracks.chh, chh, this.counter);
      this.scheduleSound(this.tracks.ohh, ohh, this.counter);
      this.scheduleSound(this.tracks.chord, chord, this.counter);
      this.scheduleSound(this.tracks.clap, clap, this.counter);
      this.playTick();
    }
    this.timerID = window.setTimeout(this.scheduler, 0);
  },
  play() {
    if (this.isPlaying === false) {
      this.counter = 0;
      this.futureTickTime = audioContext.currentTime;
      this.scheduler();
      this.isPlaying = true;
    }
  },
  stop() {
    if (this.isPlaying === true) {
      window.clearTimeout(this.timerID);
      this.isPlaying = false;
    }
  },
  playSound(sound) {
    eval(sound).play()
  },
  muteSound(obj) {
    eval(obj['sound']).mute(!obj['toggle'])
  },
  clearTracks() {
      this.tracks =  {
        kick: [],
        snare: [],
        chh: [],
        ohh: [],
        chord: [],
        clap: [],
      }
    },
    loadPreset(preset) {
      let presets = JSON.parse(localStorage.getItem('userPresets'));
      this.tempo = presets[preset].tempo;
      this.tracks = presets[preset].tracks;
    },
  },
  created() {
    StartAudioContext(audioContext)
  }

}
</script>

<style>
.drumTitle {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clefLogo {
  margin: 0 20px;
}
.drumBackground {
  
    background: linear-gradient(45deg,  #FFCB08, #FF77FF, #F15927, #50C878);
    background-size: 400% 400%;
    animation: gradient 30s ease-in-out infinite alternate-reverse;
    min-height: 100%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.SoundControls {
  margin: 10px 0 0 0;
}

.buffer {
  padding: 0px 0 30px 0;
}

</style>