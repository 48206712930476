<template>
  <v-app >
    <v-app-bar
      app
      color="accent"
      height="110px"
      :hide-on-scroll="hideOnScroll"
    >
    <div>
      <v-btn class="menuButton" color="primary" @click="toggleTheme()"><DarkMode/></v-btn>


    </div>
      <v-spacer></v-spacer>
      <router-link to="/">
      <div class="d-flex align-center">
        <v-img v-if="this.$vuetify.theme.dark"
          alt="PatMakesMusic Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logoDark.png"
          width="100"
        />
        <v-img v-else
          alt="PatMakesMusic Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logoLight.png"
          width="100"
        />
      </div>
      </router-link>

      <v-spacer></v-spacer>
      <!-- <span color="primary" class="navLabel">Menu</span> -->
       <v-menu
            bottom
            left
            transition="slide-y-transition"
            :offset-x="offset"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="menuButton"
              >
               <MenuButton/>
              </v-btn>
            </template>

             <v-list color="accent">
        <v-list-item
          v-for="(link, i) in links"
          :key="i" class="navItem"
        >
          <v-list-item-title><router-link color="primary" class="navText" :to="link.path">{{ link.title }}</router-link></v-list-item-title>
        </v-list-item>
      </v-list>
          </v-menu>

    </v-app-bar>
    <v-main :style="{background: $vuetify.theme.themes[theme].background}" class="background mainView">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import MenuButton from './components/MenuButton.vue'
import DarkMode from './components/DarkMode.vue'

export default {
  name: 'App',

  components: {
    MenuButton,
    DarkMode,
  },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
  },
  data: () => ({
    // nav items
    links: [
        { title: 'Drum Machine', path: "/drum-machine" },
        { title: 'Posts', path: "/blog" },
        { title: 'About', path: "/about" },
        { title: 'Watch Live', path: "/live" }
      ],

      hideOnScroll: true
  }),
  methods: {
    toggleTheme() {

      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      console.log(this.$vuetify.theme.dark)
    }
  }
  
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,400;0,600;1,300&display=swap');

#app {
  font-family: 'Roboto Mono', monospace;
}

.navItem {
  display: flex;
  justify-content: center;
  text-align: center;
  
}
.navText {
  text-decoration: none;
}
.background {
  
    background: linear-gradient(45deg,  #00AEEF, #FFCB08,  #F15927, #00AEEF);
    background-size: 400% 400%;
    animation: gradient 30s ease-in-out infinite alternate-reverse;
    min-height: 100vh
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.navLabel {
  padding: 30px 0 0 0;
}
.menuButton {
  min-width: 80px !important;
}
h1 {
  opacity: 1 !important;
}
</style>
