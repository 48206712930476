import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
          light: {
            primary: '#F15927',
            secondary: '#00AEEF',
            accent: '#DEDEDE',
            background: '#ECECEC',
          },
          dark: {
            primary: '#FFCB08',
            secondary: '#00AEEF',
            accent: '#121212',
            background: '#121212',
          },
        },
      },
});
