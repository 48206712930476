<template>
  <v-container class="container">
    <v-row class="text-center background" >
      <v-col cols="12" >
        
        <v-img v-if="this.$vuetify.theme.dark"
          src="@/assets/DarkClefHero.png"
          class="my-3 hero"
          contain
          height="400"
        />
        <v-img v-else
          src="@/assets/LightClefHero.png"
          class="my-3 hero"
          contain
          height="400"
        />
      
        <h1  :style="{background: $vuetify.theme.themes[theme].background}" class="display-2 font-weight-normal mb-3 Welcome">
          Welcome!
        </h1>

        <p class="subheading font-weight-regular">
          
        </p>
      </v-col>

      
    </v-row>
  </v-container>
</template>

<script>

  export default {

    name: 'Home',
    

    data() {
      return {

        }
      },
      computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
      },
      methods: {
        
      }
    }
</script>

<style>
h1 {
  padding: 10px;
  display: inline-block;
  border-radius: 10pt;
  margin: 15px;
  opacity: 80%;
}

html, body {
  width: 100%;
  height:100%;
}
.container {
  max-width: 100%;
}



</style>
