<template>

<v-container> 
    <template v-for="(track, name) in tracks" >
        <v-row :key="name" align="center" dense class="justify-center">
            <v-row class="buttonRow"> 
                <v-col cols="6" class="buttonCol">
                    <h4 class="trackLabel">{{name}}</h4>
                <v-switch inset dense color="primary"
                v-model="toggles[name]"
                @change="muteSound(name, toggles[name])"
                ></v-switch>
                <!-- <v-btn fab raised small @click="playSound(name)">
                    <v-icon color="primary" class="green--text" v-show="toggles[name]">mdi-volume-high</v-icon>
                    <v-icon class="grey--text" v-show="!toggles[name]">mdi-volume-off</v-icon>
                </v-btn> -->
                </v-col>
            </v-row>
            <v-row class="seqRow">
                <v-btn-toggle color="primary" v-model="tracks[name]" multiple max-width="300px">
                    <v-btn class="seqButtons" color="primary" height="30" small icon v-for="n in 16" :key="n">
                    </v-btn>    
                </v-btn-toggle>
            </v-row>
        </v-row>
    </template>
</v-container>
</template>
<script>

export default {
    props: ["tracks"],

    data() {
        return {
            toggles: {
                kick: true,
                snare: true,
                chh: true,
                ohh: true,
                chord: true,
                clap: true,
            },
        };
    },
    methods: {
        playSound(sound) {
            this.$emit('playsound', sound);
        },
        muteSound(sound, toggle) {
            this.$emit('mutesound', {sound, toggle});
        },
    }
}

</script>
<style scoped>
.trackLabel {
    padding: 0 15px;
}
.buttonRow {
    min-height: 50px;
    max-width: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buttonCol {
    display:flex;
    justify-content: center;
    align-items: center;
}
.seqButtons {
    max-width: 10px;
    padding: 0 9px !important;
}
.seqRow {
    margin: 4px 0 0 8px;
}
</style>
