<template>
<div class="background">
  <div class="zeroAuto" v-if="currentPost.id>=0">
    <div class="blogClose">
      <a href="#" class="previous" @click="reset()" id="top">&laquo;</a>
    </div>
    <div class="blogCenter">
    <h1>{{ currentPost.title }}</h1>
    </div>
    <div class="blogLeft">
    <span class="date">{{ currentPost.date }}</span>
    </div>
    
    <div class="blogCenter" id="blogshow">
    
    
    <div class="blogContent">
      
     <p class="blogWords" v-html="currentPost.Content" :post="post">
    </p>
    <img class="blogPic" :src="currentPost.Image">
    </div>
  </div>
  </div>
<div v-else>
  <div class="blogwrap">
    <h1>Pat Makes Posts</h1>
    <div class="blog">
      <div class="cardWrap" v-for="post in posts" 
    :key="post.id"
     :post="post"
     @click="setActivePost(post)">
    <BlogCard :post="post"/>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import BlogCard from '../components/BlogCard.vue';
// import BlogService from '@/services/BlogService.js'

export default {
    components: {
        BlogCard,
    },
    data() {
      return {
        posts: [],
        currentPost: {},
        // currentIndex: -1,
      }
    },
    created() {
  fetch('https://raw.githubusercontent.com/patmakesmusic/pmmBlogger/main/blogdb.json')
  .then(response => response.json())
  .then(data => {
    console.log(data.posts)
    var postsRaw = data.posts
   var sorted = postsRaw.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0 ));
   this.posts = sorted.reverse()
   console.log(sorted)
  },
  );
},
methods: {
    setActivePost(post) {
      window.scrollTo(0,0); 
      this.currentPost = post
      console.log("I'm working") 
        },
        reset() {
          this.currentPost = {}
        }
  }
}
</script>

<style scoped>
.zeroAuto {
  margin: 0 auto;
}
h1 {
  text-shadow: 2px 2px #00AEEF;
}
.blogClose {
  float: right;
  margin: 30px 25% 5px 0;
}

.blogwrap {
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.blogCenter {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

}
.blogLeft {
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}
.blog {
    display: flex;
    flex-wrap: wrap;
    min-width: 125px;
    justify-content: center;
    align-items: center;
}
.blogPic {
  width: 45%;
    position: static;
    margin: 15px;
    z-index: 0;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 15px 0 rgba(0, 0, 0, 0.19);
}
.blogWords {
  float: left;
  font-size: 15pt;
  
}
.blogwrap {
  text-align: center;
}
.blogContent {
    text-align: left;
    border-radius: 5pt;
    padding: 20px;
    margin: 5px auto 100px auto;
    width: 80vw;
    max-width: 800px;
    line-height: 1.4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
a {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}

a:hover {
  background-color: #ddd;
  color: black;
}

.previous {
  background-color: #f1f1f1;
  color: black;
  justify-content: center;
}

.next {
  background-color: #04AA6D;
  color: white;
}

.round {
  border-radius: 50%;
}
.cardWrap {
  margin: 5px;
}
.date {
  font-size: 9pt;
}
</style>
